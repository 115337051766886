import { Injectable } from '@angular/core';
import { FacilityInfo } from '@dpdhl-iot/api/backend';
import {
  AnalyticsTelemetryService,
  DevicesByMappedPositionModelIGuidEntityIdIZone,
  DevicesByMappedPositionResponseModel,
} from '@dpdhl-iot/api/data';
import { ApplicationFacilityService, CoreConstants } from '@dpdhl-iot/shared';
import { ApplicationDataService } from '@dpdhl/iot-shared-ui';
import { IotApplicationModel } from '@dpdhl/iot-shared-ui/lib/iot-application-shell/api/management-api';
import { BehaviorSubject, combineLatest, filter, switchMap, take } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ZonesService {
  public availableDevices: DevicesByMappedPositionResponseModel | undefined;
  public inTransitDevices: DevicesByMappedPositionResponseModel | undefined;

  private readonly currentDevicesProvider = new BehaviorSubject<boolean>(false);

  constructor(
    private readonly analyticsTelemetryService: AnalyticsTelemetryService,
    private readonly applicationDataService: ApplicationDataService,
    private readonly applicationFacilityService: ApplicationFacilityService,
  ) {
    this.applicationFacilityService.filteredFacilities.subscribe((res) =>
      this.updateDevicesForZones(res),
    );
  }

  public GetDevicesCountForZones(zoneIds: string[]): [number, number] | undefined {
    let availableDevices: string[] | undefined;
    let inTransitDevices: string[] | undefined;
    zoneIds.forEach((zoneId) => {
      let availableDevicesForZone;
      let inTransitDevicesForZone;
      if (this.availableDevices) {
        availableDevicesForZone = this.availableDevices.devicesPerZone.find(
          (a) => a.positionId === zoneId,
        );
        if (availableDevicesForZone) {
          if (!availableDevices) {
            availableDevices = [];
          }
          availableDevices.push(...availableDevicesForZone.devices);
        }
      }
      if (this.inTransitDevices) {
        inTransitDevicesForZone = this.inTransitDevices.devicesPerZone.find(
          (a) => a.positionId === zoneId,
        );
        if (inTransitDevicesForZone) {
          if (!inTransitDevices) {
            inTransitDevices = [];
          }
          inTransitDevices.push(...inTransitDevicesForZone.devices);
        }
      }
    });

    if (!availableDevices || !inTransitDevices) {
      return;
    }
    return [new Set(availableDevices).size, new Set(inTransitDevices).size];
  }

  private updateDevicesForZones(facilities: FacilityInfo[]) {
    this.currentDevicesProvider.next(false);
    const inTransitCutoffThreshold = facilities.find((a) => a.inTransitCutOffThreshold);
    const inTransitCutoffThreshold_seconds = inTransitCutoffThreshold?.inTransitCutOffThreshold
      ? inTransitCutoffThreshold.inTransitCutOffThreshold
      : CoreConstants.FIFTEEN_MINUTES_INTERVAL_SECONDS;
    const zoneIds = new Set(
      facilities
        .map((a) => a.areas)
        .flat()
        .filter((a) => a?.zones)
        .map((a) => a?.zones ?? [])
        .flat(),
    );
    if (zoneIds.size === 0) {
      return;
    }

    const availableResponse = this.applicationDataService.application$.pipe(
      filter((a) => !!a.application),
      map((a) => a.application as IotApplicationModel),
      switchMap((a: IotApplicationModel) =>
        this.analyticsTelemetryService.devicesByMappedPosition({
          devicesByMappedPositionRequest: {
            applicationId: a.id,
            zoneIDs: Array.from(zoneIds.values()),
            geoFenceIDs: [],
            threshold: inTransitCutoffThreshold_seconds,
          },
          xApiVersion: CoreConstants.API_VERSION,
        }),
      ),
      tap((res) => {
        this.availableDevices = res;
      }),
    );

    const inTransitResponse = this.applicationDataService.application$.pipe(
      filter((a) => !!a.application),
      map((a) => a.application as IotApplicationModel),
      switchMap((a: IotApplicationModel) =>
        this.analyticsTelemetryService.devicesByMappedPosition({
          xApiVersion: CoreConstants.API_VERSION,
          devicesByMappedPositionRequest: {
            applicationId: a.id,
            zoneIDs: Array.from(zoneIds.values()),
            geoFenceIDs: [],
            inTransit: true,
            threshold: CoreConstants.ONE_MONTH_INTERVAL_SECONDS,
          },
        }),
      ),
    );

    combineLatest([availableResponse, inTransitResponse])
      .pipe(take(1))
      .subscribe(([availableRes, inTransitRes]: DevicesByMappedPositionResponseModel[]) => {
        const inTransitResult: DevicesByMappedPositionResponseModel = {
          devicesPerGeoFence: [],
          devicesPerZone: [],
        };

        inTransitRes.devicesPerZone.forEach((zone) => {
          const positionModel = this.GetDevicesByMappedPosition(availableRes, zone);
          inTransitResult.devicesPerZone.push(positionModel);
        });
        this.inTransitDevices = inTransitResult;
        this.currentDevicesProvider.next(true);
      });
  }

  private GetDevicesByMappedPosition(
    availableRes: DevicesByMappedPositionResponseModel,
    zone: DevicesByMappedPositionModelIGuidEntityIdIZone,
  ): DevicesByMappedPositionModelIGuidEntityIdIZone {
    const positionModel: DevicesByMappedPositionModelIGuidEntityIdIZone = {
      positionId: zone.positionId,
      devices: [],
    };
    const availablePositionModel = availableRes.devicesPerZone.find(
      (z) => z.positionId === zone.positionId,
    );
    if (!availablePositionModel) {
      positionModel.devices.push(...zone.devices);
    } else {
      positionModel.devices.push(
        ...zone.devices.filter(
          (deviceId) => !availablePositionModel.devices.find((d) => d === deviceId),
        ),
      );
    }
    return positionModel;
  }
}
