/**
 * Predictive Maintenance API
 * This API provides access to current and forecast data of different sorter types.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec, HttpContext 
        }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

// @ts-ignore
import { CellAlertDataHeatMap } from '../model/cell-alert-data-heat-map';
// @ts-ignore
import { FrequencyDataImageWithTimeInfo } from '../model/frequency-data-image-with-time-info';
// @ts-ignore
import { Sensor } from '../model/sensor';
// @ts-ignore
import { ValueAtTime } from '../model/value-at-time';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { PrognosisConfiguration }                                     from '../configuration';



@Injectable({
  providedIn: 'root'
})
export class PrognosisControllerService {

    protected basePath = 'https://dev.conditionmonitoring.iot.dhl.com';
    public defaultHeaders = new HttpHeaders();
    public configuration = new PrognosisConfiguration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string|string[], @Optional() configuration: PrognosisConfiguration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (Array.isArray(basePath) && basePath.length > 0) {
                basePath = basePath[0];
            }

            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    // @ts-ignore
    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key, (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * Returns 2 images in PNG format, frequencies and timestamps with the alert triggering factors of a cell.
     * @param deviceId 
     * @param cell 
     * @param from start time in ms since 1.1.1970 (UTC)
     * @param to end time in ms since 1.1.1970 (UTC)
     * @param width image width
     * @param height image height
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAlertTriggeringFactorsOfCell(deviceId: string, cell: number, from: number, to: number, width: number, height: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<CellAlertDataHeatMap>;
    public getAlertTriggeringFactorsOfCell(deviceId: string, cell: number, from: number, to: number, width: number, height: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<CellAlertDataHeatMap>>;
    public getAlertTriggeringFactorsOfCell(deviceId: string, cell: number, from: number, to: number, width: number, height: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<CellAlertDataHeatMap>>;
    public getAlertTriggeringFactorsOfCell(deviceId: string, cell: number, from: number, to: number, width: number, height: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (deviceId === null || deviceId === undefined) {
            throw new Error('Required parameter deviceId was null or undefined when calling getAlertTriggeringFactorsOfCell.');
        }
        if (cell === null || cell === undefined) {
            throw new Error('Required parameter cell was null or undefined when calling getAlertTriggeringFactorsOfCell.');
        }
        if (from === null || from === undefined) {
            throw new Error('Required parameter from was null or undefined when calling getAlertTriggeringFactorsOfCell.');
        }
        if (to === null || to === undefined) {
            throw new Error('Required parameter to was null or undefined when calling getAlertTriggeringFactorsOfCell.');
        }
        if (width === null || width === undefined) {
            throw new Error('Required parameter width was null or undefined when calling getAlertTriggeringFactorsOfCell.');
        }
        if (height === null || height === undefined) {
            throw new Error('Required parameter height was null or undefined when calling getAlertTriggeringFactorsOfCell.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (from !== undefined && from !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>from, 'from');
        }
        if (to !== undefined && to !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>to, 'to');
        }
        if (width !== undefined && width !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>width, 'width');
        }
        if (height !== undefined && height !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>height, 'height');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (PredictiveMaintenance) required
        localVarCredential = this.configuration.lookupCredential('PredictiveMaintenance');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/prognosis/api/v1/sensors/${this.configuration.encodeParam({name: "deviceId", value: deviceId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/alerttriggeringfactors/${this.configuration.encodeParam({name: "cell", value: cell, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int32"})}`;
        return this.httpClient.request<CellAlertDataHeatMap>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Returns the list of volume levels in the given time range.
     * @param deviceId 
     * @param type ABSOLUTE or RELATIVE Values
     * @param from time range start in ms since 1.1.1970 (UTC)
     * @param to time range end in ms since 1.1.1970 (UTC)
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAverageVolumeLevels(deviceId: string, type: 'ABSOLUTE' | 'RELATIVE', from: number, to: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<ValueAtTime>>;
    public getAverageVolumeLevels(deviceId: string, type: 'ABSOLUTE' | 'RELATIVE', from: number, to: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<ValueAtTime>>>;
    public getAverageVolumeLevels(deviceId: string, type: 'ABSOLUTE' | 'RELATIVE', from: number, to: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<ValueAtTime>>>;
    public getAverageVolumeLevels(deviceId: string, type: 'ABSOLUTE' | 'RELATIVE', from: number, to: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (deviceId === null || deviceId === undefined) {
            throw new Error('Required parameter deviceId was null or undefined when calling getAverageVolumeLevels.');
        }
        if (type === null || type === undefined) {
            throw new Error('Required parameter type was null or undefined when calling getAverageVolumeLevels.');
        }
        if (from === null || from === undefined) {
            throw new Error('Required parameter from was null or undefined when calling getAverageVolumeLevels.');
        }
        if (to === null || to === undefined) {
            throw new Error('Required parameter to was null or undefined when calling getAverageVolumeLevels.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (type !== undefined && type !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>type, 'type');
        }
        if (from !== undefined && from !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>from, 'from');
        }
        if (to !== undefined && to !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>to, 'to');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (PredictiveMaintenance) required
        localVarCredential = this.configuration.lookupCredential('PredictiveMaintenance');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/prognosis/api/v1/sensors/${this.configuration.encodeParam({name: "deviceId", value: deviceId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/volumelevels/average`;
        return this.httpClient.request<Array<ValueAtTime>>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Returns the list of deviations of standard belt length (meters) in the given time range.
     * @param deviceId 
     * @param from time range start in ms since 1.1.1970 (UTC)
     * @param to time range end in ms since 1.1.1970 (UTC)
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getBeltLengthDeviations(deviceId: string, from: number, to: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<ValueAtTime>>;
    public getBeltLengthDeviations(deviceId: string, from: number, to: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<ValueAtTime>>>;
    public getBeltLengthDeviations(deviceId: string, from: number, to: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<ValueAtTime>>>;
    public getBeltLengthDeviations(deviceId: string, from: number, to: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (deviceId === null || deviceId === undefined) {
            throw new Error('Required parameter deviceId was null or undefined when calling getBeltLengthDeviations.');
        }
        if (from === null || from === undefined) {
            throw new Error('Required parameter from was null or undefined when calling getBeltLengthDeviations.');
        }
        if (to === null || to === undefined) {
            throw new Error('Required parameter to was null or undefined when calling getBeltLengthDeviations.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (from !== undefined && from !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>from, 'from');
        }
        if (to !== undefined && to !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>to, 'to');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (PredictiveMaintenance) required
        localVarCredential = this.configuration.lookupCredential('PredictiveMaintenance');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/prognosis/api/v1/sensors/${this.configuration.encodeParam({name: "deviceId", value: deviceId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/beltlengthdeviations`;
        return this.httpClient.request<Array<ValueAtTime>>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Returns the list of data-complete circulation belt start times in the given time range.
     * @param deviceId 
     * @param from time range start in ms since 1.1.1970 (UTC)
     * @param to time range end in ms since 1.1.1970 (UTC)
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCirculationStarts(deviceId: string, from: number, to: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<number>>;
    public getCirculationStarts(deviceId: string, from: number, to: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<number>>>;
    public getCirculationStarts(deviceId: string, from: number, to: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<number>>>;
    public getCirculationStarts(deviceId: string, from: number, to: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (deviceId === null || deviceId === undefined) {
            throw new Error('Required parameter deviceId was null or undefined when calling getCirculationStarts.');
        }
        if (from === null || from === undefined) {
            throw new Error('Required parameter from was null or undefined when calling getCirculationStarts.');
        }
        if (to === null || to === undefined) {
            throw new Error('Required parameter to was null or undefined when calling getCirculationStarts.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (from !== undefined && from !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>from, 'from');
        }
        if (to !== undefined && to !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>to, 'to');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (PredictiveMaintenance) required
        localVarCredential = this.configuration.lookupCredential('PredictiveMaintenance');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/prognosis/api/v1/sensors/${this.configuration.encodeParam({name: "deviceId", value: deviceId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/circulationstarts`;
        return this.httpClient.request<Array<number>>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Returns the number of circulations per day in the given time range.
     * @param deviceId 
     * @param from time range start in ms since 1.1.1970 (UTC)
     * @param to time range end in ms since 1.1.1970 (UTC)
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCirculationsPerDay(deviceId: string, from: number, to: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<ValueAtTime>>;
    public getCirculationsPerDay(deviceId: string, from: number, to: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<ValueAtTime>>>;
    public getCirculationsPerDay(deviceId: string, from: number, to: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<ValueAtTime>>>;
    public getCirculationsPerDay(deviceId: string, from: number, to: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (deviceId === null || deviceId === undefined) {
            throw new Error('Required parameter deviceId was null or undefined when calling getCirculationsPerDay.');
        }
        if (from === null || from === undefined) {
            throw new Error('Required parameter from was null or undefined when calling getCirculationsPerDay.');
        }
        if (to === null || to === undefined) {
            throw new Error('Required parameter to was null or undefined when calling getCirculationsPerDay.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (from !== undefined && from !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>from, 'from');
        }
        if (to !== undefined && to !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>to, 'to');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (PredictiveMaintenance) required
        localVarCredential = this.configuration.lookupCredential('PredictiveMaintenance');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/prognosis/api/v1/sensors/${this.configuration.encodeParam({name: "deviceId", value: deviceId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/circulationsperday`;
        return this.httpClient.request<Array<ValueAtTime>>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Returns the current volume level of a sensor based on the volume reference value.
     * @param deviceId 
     * @param type ABSOLUTE or RELATIVE Values
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCurrentVolumeLevel(deviceId: string, type: 'ABSOLUTE' | 'RELATIVE', observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<ValueAtTime>;
    public getCurrentVolumeLevel(deviceId: string, type: 'ABSOLUTE' | 'RELATIVE', observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<ValueAtTime>>;
    public getCurrentVolumeLevel(deviceId: string, type: 'ABSOLUTE' | 'RELATIVE', observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<ValueAtTime>>;
    public getCurrentVolumeLevel(deviceId: string, type: 'ABSOLUTE' | 'RELATIVE', observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (deviceId === null || deviceId === undefined) {
            throw new Error('Required parameter deviceId was null or undefined when calling getCurrentVolumeLevel.');
        }
        if (type === null || type === undefined) {
            throw new Error('Required parameter type was null or undefined when calling getCurrentVolumeLevel.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (type !== undefined && type !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>type, 'type');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (PredictiveMaintenance) required
        localVarCredential = this.configuration.lookupCredential('PredictiveMaintenance');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/prognosis/api/v1/sensors/${this.configuration.encodeParam({name: "deviceId", value: deviceId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/volumelevels/average/current`;
        return this.httpClient.request<ValueAtTime>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Returns an image (PNG format) with the frequency analysis results.
     * @param deviceId 
     * @param from start time in ms since 1.1.1970 (UTC) (end time determined by belt length)
     * @param width image width
     * @param height image height
     * @param numberofcirculations number of circulations
     * @param imagedatasource image data source (CIRCULATION [default] or RAW)
     * @param colorcontrol color control value (min&#x3D;2, max &#x3D; 1000, default &#x3D; 5)
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getFreqData(deviceId: string, from: number, width: number, height: number, numberofcirculations?: number, imagedatasource?: 'RAW' | 'CIRCULATION', colorcontrol?: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'image/png', context?: HttpContext}): Observable<Blob>;
    public getFreqData(deviceId: string, from: number, width: number, height: number, numberofcirculations?: number, imagedatasource?: 'RAW' | 'CIRCULATION', colorcontrol?: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'image/png', context?: HttpContext}): Observable<HttpResponse<Blob>>;
    public getFreqData(deviceId: string, from: number, width: number, height: number, numberofcirculations?: number, imagedatasource?: 'RAW' | 'CIRCULATION', colorcontrol?: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'image/png', context?: HttpContext}): Observable<HttpEvent<Blob>>;
    public getFreqData(deviceId: string, from: number, width: number, height: number, numberofcirculations?: number, imagedatasource?: 'RAW' | 'CIRCULATION', colorcontrol?: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'image/png', context?: HttpContext}): Observable<any> {
        if (deviceId === null || deviceId === undefined) {
            throw new Error('Required parameter deviceId was null or undefined when calling getFreqData.');
        }
        if (from === null || from === undefined) {
            throw new Error('Required parameter from was null or undefined when calling getFreqData.');
        }
        if (width === null || width === undefined) {
            throw new Error('Required parameter width was null or undefined when calling getFreqData.');
        }
        if (height === null || height === undefined) {
            throw new Error('Required parameter height was null or undefined when calling getFreqData.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (from !== undefined && from !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>from, 'from');
        }
        if (width !== undefined && width !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>width, 'width');
        }
        if (height !== undefined && height !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>height, 'height');
        }
        if (numberofcirculations !== undefined && numberofcirculations !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>numberofcirculations, 'numberofcirculations');
        }
        if (imagedatasource !== undefined && imagedatasource !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>imagedatasource, 'imagedatasource');
        }
        if (colorcontrol !== undefined && colorcontrol !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>colorcontrol, 'colorcontrol');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (PredictiveMaintenance) required
        localVarCredential = this.configuration.lookupCredential('PredictiveMaintenance');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'image/png'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let localVarPath = `/prognosis/api/v1/sensors/${this.configuration.encodeParam({name: "deviceId", value: deviceId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/freqdata`;
        return this.httpClient.request('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Returns an image (PNG format) which shows the availability of frequency analysis data in the specified time range.
     * @param deviceId 
     * @param from time range start in ms since 1.1.1970 (UTC)
     * @param to time range end in ms since 1.1.1970 (UTC)
     * @param width image width
     * @param height image height
     * @param imagedatasource image data source (CIRCULATION [default] or RAW)
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getFreqDataAvailability(deviceId: string, from: number, to: number, width: number, height: number, imagedatasource?: 'RAW' | 'CIRCULATION', observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'image/png', context?: HttpContext}): Observable<Blob>;
    public getFreqDataAvailability(deviceId: string, from: number, to: number, width: number, height: number, imagedatasource?: 'RAW' | 'CIRCULATION', observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'image/png', context?: HttpContext}): Observable<HttpResponse<Blob>>;
    public getFreqDataAvailability(deviceId: string, from: number, to: number, width: number, height: number, imagedatasource?: 'RAW' | 'CIRCULATION', observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'image/png', context?: HttpContext}): Observable<HttpEvent<Blob>>;
    public getFreqDataAvailability(deviceId: string, from: number, to: number, width: number, height: number, imagedatasource?: 'RAW' | 'CIRCULATION', observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'image/png', context?: HttpContext}): Observable<any> {
        if (deviceId === null || deviceId === undefined) {
            throw new Error('Required parameter deviceId was null or undefined when calling getFreqDataAvailability.');
        }
        if (from === null || from === undefined) {
            throw new Error('Required parameter from was null or undefined when calling getFreqDataAvailability.');
        }
        if (to === null || to === undefined) {
            throw new Error('Required parameter to was null or undefined when calling getFreqDataAvailability.');
        }
        if (width === null || width === undefined) {
            throw new Error('Required parameter width was null or undefined when calling getFreqDataAvailability.');
        }
        if (height === null || height === undefined) {
            throw new Error('Required parameter height was null or undefined when calling getFreqDataAvailability.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (from !== undefined && from !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>from, 'from');
        }
        if (to !== undefined && to !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>to, 'to');
        }
        if (width !== undefined && width !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>width, 'width');
        }
        if (height !== undefined && height !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>height, 'height');
        }
        if (imagedatasource !== undefined && imagedatasource !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>imagedatasource, 'imagedatasource');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (PredictiveMaintenance) required
        localVarCredential = this.configuration.lookupCredential('PredictiveMaintenance');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'image/png'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let localVarPath = `/prognosis/api/v1/sensors/${this.configuration.encodeParam({name: "deviceId", value: deviceId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/freqdatapresent`;
        return this.httpClient.request('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Returns an image (PNG format) with the frequency analysis results. Extended options.
     * @param deviceId 
     * @param circulations IDs of circulations which should be used for image generation
     * @param width image width
     * @param height image height
     * @param colorcontrol color control value (min&#x3D;2, max &#x3D; 1000, default &#x3D; 5)
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getFreqDataExtended(deviceId: string, circulations: Array<number>, width?: number, height?: number, colorcontrol?: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'image/png', context?: HttpContext}): Observable<Blob>;
    public getFreqDataExtended(deviceId: string, circulations: Array<number>, width?: number, height?: number, colorcontrol?: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'image/png', context?: HttpContext}): Observable<HttpResponse<Blob>>;
    public getFreqDataExtended(deviceId: string, circulations: Array<number>, width?: number, height?: number, colorcontrol?: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'image/png', context?: HttpContext}): Observable<HttpEvent<Blob>>;
    public getFreqDataExtended(deviceId: string, circulations: Array<number>, width?: number, height?: number, colorcontrol?: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'image/png', context?: HttpContext}): Observable<any> {
        if (deviceId === null || deviceId === undefined) {
            throw new Error('Required parameter deviceId was null or undefined when calling getFreqDataExtended.');
        }
        if (circulations === null || circulations === undefined) {
            throw new Error('Required parameter circulations was null or undefined when calling getFreqDataExtended.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (circulations) {
            circulations.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'circulations');
            })
        }
        if (width !== undefined && width !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>width, 'width');
        }
        if (height !== undefined && height !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>height, 'height');
        }
        if (colorcontrol !== undefined && colorcontrol !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>colorcontrol, 'colorcontrol');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (PredictiveMaintenance) required
        localVarCredential = this.configuration.lookupCredential('PredictiveMaintenance');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'image/png'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let localVarPath = `/prognosis/api/v1/sensors/${this.configuration.encodeParam({name: "deviceId", value: deviceId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/freqdataextended`;
        return this.httpClient.request('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Returns an image (PNG format) with the frequency analysis results of a cell.
     * @param deviceId 
     * @param cell 
     * @param from start time in ms since 1.1.1970 (UTC)
     * @param to end time in ms since 1.1.1970 (UTC)
     * @param width image width
     * @param height image height
     * @param colorcontrol color control value (min&#x3D;2, max &#x3D; 1000, default &#x3D; 5)
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getFreqDataOfCell(deviceId: string, cell: number, from: number, to: number, width: number, height: number, colorcontrol?: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'image/png', context?: HttpContext}): Observable<Blob>;
    public getFreqDataOfCell(deviceId: string, cell: number, from: number, to: number, width: number, height: number, colorcontrol?: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'image/png', context?: HttpContext}): Observable<HttpResponse<Blob>>;
    public getFreqDataOfCell(deviceId: string, cell: number, from: number, to: number, width: number, height: number, colorcontrol?: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'image/png', context?: HttpContext}): Observable<HttpEvent<Blob>>;
    public getFreqDataOfCell(deviceId: string, cell: number, from: number, to: number, width: number, height: number, colorcontrol?: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'image/png', context?: HttpContext}): Observable<any> {
        if (deviceId === null || deviceId === undefined) {
            throw new Error('Required parameter deviceId was null or undefined when calling getFreqDataOfCell.');
        }
        if (cell === null || cell === undefined) {
            throw new Error('Required parameter cell was null or undefined when calling getFreqDataOfCell.');
        }
        if (from === null || from === undefined) {
            throw new Error('Required parameter from was null or undefined when calling getFreqDataOfCell.');
        }
        if (to === null || to === undefined) {
            throw new Error('Required parameter to was null or undefined when calling getFreqDataOfCell.');
        }
        if (width === null || width === undefined) {
            throw new Error('Required parameter width was null or undefined when calling getFreqDataOfCell.');
        }
        if (height === null || height === undefined) {
            throw new Error('Required parameter height was null or undefined when calling getFreqDataOfCell.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (from !== undefined && from !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>from, 'from');
        }
        if (to !== undefined && to !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>to, 'to');
        }
        if (width !== undefined && width !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>width, 'width');
        }
        if (height !== undefined && height !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>height, 'height');
        }
        if (colorcontrol !== undefined && colorcontrol !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>colorcontrol, 'colorcontrol');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (PredictiveMaintenance) required
        localVarCredential = this.configuration.lookupCredential('PredictiveMaintenance');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'image/png'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let localVarPath = `/prognosis/api/v1/sensors/${this.configuration.encodeParam({name: "deviceId", value: deviceId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/freqdata/${this.configuration.encodeParam({name: "cell", value: cell, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int32"})}`;
        return this.httpClient.request('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Returns an image (PNG format) with the frequency analysis results of a cell including timestamps.
     * @param deviceId 
     * @param cell 
     * @param from start time in ms since 1.1.1970 (UTC)
     * @param to end time in ms since 1.1.1970 (UTC)
     * @param width image width
     * @param height image height
     * @param colorcontrol color control value (min&#x3D;2, max &#x3D; 1000, default &#x3D; 5)
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getFreqDataOfCellWithTime(deviceId: string, cell: number, from: number, to: number, width: number, height: number, colorcontrol?: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<FrequencyDataImageWithTimeInfo>;
    public getFreqDataOfCellWithTime(deviceId: string, cell: number, from: number, to: number, width: number, height: number, colorcontrol?: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<FrequencyDataImageWithTimeInfo>>;
    public getFreqDataOfCellWithTime(deviceId: string, cell: number, from: number, to: number, width: number, height: number, colorcontrol?: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<FrequencyDataImageWithTimeInfo>>;
    public getFreqDataOfCellWithTime(deviceId: string, cell: number, from: number, to: number, width: number, height: number, colorcontrol?: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (deviceId === null || deviceId === undefined) {
            throw new Error('Required parameter deviceId was null or undefined when calling getFreqDataOfCellWithTime.');
        }
        if (cell === null || cell === undefined) {
            throw new Error('Required parameter cell was null or undefined when calling getFreqDataOfCellWithTime.');
        }
        if (from === null || from === undefined) {
            throw new Error('Required parameter from was null or undefined when calling getFreqDataOfCellWithTime.');
        }
        if (to === null || to === undefined) {
            throw new Error('Required parameter to was null or undefined when calling getFreqDataOfCellWithTime.');
        }
        if (width === null || width === undefined) {
            throw new Error('Required parameter width was null or undefined when calling getFreqDataOfCellWithTime.');
        }
        if (height === null || height === undefined) {
            throw new Error('Required parameter height was null or undefined when calling getFreqDataOfCellWithTime.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (from !== undefined && from !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>from, 'from');
        }
        if (to !== undefined && to !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>to, 'to');
        }
        if (width !== undefined && width !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>width, 'width');
        }
        if (height !== undefined && height !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>height, 'height');
        }
        if (colorcontrol !== undefined && colorcontrol !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>colorcontrol, 'colorcontrol');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (PredictiveMaintenance) required
        localVarCredential = this.configuration.lookupCredential('PredictiveMaintenance');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/prognosis/api/v1/sensors/${this.configuration.encodeParam({name: "deviceId", value: deviceId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/freqdatacell/${this.configuration.encodeParam({name: "cell", value: cell, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int32"})}`;
        return this.httpClient.request<FrequencyDataImageWithTimeInfo>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Provides application health state.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getHealthState(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*', context?: HttpContext}): Observable<object>;
    public getHealthState(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*', context?: HttpContext}): Observable<HttpResponse<object>>;
    public getHealthState(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*', context?: HttpContext}): Observable<HttpEvent<object>>;
    public getHealthState(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*', context?: HttpContext}): Observable<any> {

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/prognosis/api/v1/healthz`;
        return this.httpClient.request<object>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Returns the current maximum volume level of a sensor based on the volume reference value.
     * @param deviceId 
     * @param type ABSOLUTE or RELATIVE Values
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getMaximumCurrentVolumeLevel(deviceId: string, type: 'ABSOLUTE' | 'RELATIVE', observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<ValueAtTime>;
    public getMaximumCurrentVolumeLevel(deviceId: string, type: 'ABSOLUTE' | 'RELATIVE', observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<ValueAtTime>>;
    public getMaximumCurrentVolumeLevel(deviceId: string, type: 'ABSOLUTE' | 'RELATIVE', observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<ValueAtTime>>;
    public getMaximumCurrentVolumeLevel(deviceId: string, type: 'ABSOLUTE' | 'RELATIVE', observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (deviceId === null || deviceId === undefined) {
            throw new Error('Required parameter deviceId was null or undefined when calling getMaximumCurrentVolumeLevel.');
        }
        if (type === null || type === undefined) {
            throw new Error('Required parameter type was null or undefined when calling getMaximumCurrentVolumeLevel.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (type !== undefined && type !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>type, 'type');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (PredictiveMaintenance) required
        localVarCredential = this.configuration.lookupCredential('PredictiveMaintenance');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/prognosis/api/v1/sensors/${this.configuration.encodeParam({name: "deviceId", value: deviceId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/volumelevels/maximum/current`;
        return this.httpClient.request<ValueAtTime>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Returns the list of maximum volume levels in the given time range.
     * @param deviceId 
     * @param type ABSOLUTE or RELATIVE Values
     * @param from time range start in ms since 1.1.1970 (UTC)
     * @param to time range end in ms since 1.1.1970 (UTC)
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getMaximumVolumeLevels(deviceId: string, type: 'ABSOLUTE' | 'RELATIVE', from: number, to: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<ValueAtTime>>;
    public getMaximumVolumeLevels(deviceId: string, type: 'ABSOLUTE' | 'RELATIVE', from: number, to: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<ValueAtTime>>>;
    public getMaximumVolumeLevels(deviceId: string, type: 'ABSOLUTE' | 'RELATIVE', from: number, to: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<ValueAtTime>>>;
    public getMaximumVolumeLevels(deviceId: string, type: 'ABSOLUTE' | 'RELATIVE', from: number, to: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (deviceId === null || deviceId === undefined) {
            throw new Error('Required parameter deviceId was null or undefined when calling getMaximumVolumeLevels.');
        }
        if (type === null || type === undefined) {
            throw new Error('Required parameter type was null or undefined when calling getMaximumVolumeLevels.');
        }
        if (from === null || from === undefined) {
            throw new Error('Required parameter from was null or undefined when calling getMaximumVolumeLevels.');
        }
        if (to === null || to === undefined) {
            throw new Error('Required parameter to was null or undefined when calling getMaximumVolumeLevels.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (type !== undefined && type !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>type, 'type');
        }
        if (from !== undefined && from !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>from, 'from');
        }
        if (to !== undefined && to !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>to, 'to');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (PredictiveMaintenance) required
        localVarCredential = this.configuration.lookupCredential('PredictiveMaintenance');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/prognosis/api/v1/sensors/${this.configuration.encodeParam({name: "deviceId", value: deviceId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/volumelevels/maximum`;
        return this.httpClient.request<Array<ValueAtTime>>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Returns an image (PNG format) with the noise range gradient.
     * @param width image width
     * @param height image height
     * @param colorcontrol color control value (min&#x3D;2, max &#x3D; 1000, default &#x3D; 5)
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getNoiseRangeGradient(width: number, height: number, colorcontrol?: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'image/png', context?: HttpContext}): Observable<Blob>;
    public getNoiseRangeGradient(width: number, height: number, colorcontrol?: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'image/png', context?: HttpContext}): Observable<HttpResponse<Blob>>;
    public getNoiseRangeGradient(width: number, height: number, colorcontrol?: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'image/png', context?: HttpContext}): Observable<HttpEvent<Blob>>;
    public getNoiseRangeGradient(width: number, height: number, colorcontrol?: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'image/png', context?: HttpContext}): Observable<any> {
        if (width === null || width === undefined) {
            throw new Error('Required parameter width was null or undefined when calling getNoiseRangeGradient.');
        }
        if (height === null || height === undefined) {
            throw new Error('Required parameter height was null or undefined when calling getNoiseRangeGradient.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (width !== undefined && width !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>width, 'width');
        }
        if (height !== undefined && height !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>height, 'height');
        }
        if (colorcontrol !== undefined && colorcontrol !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>colorcontrol, 'colorcontrol');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (PredictiveMaintenance) required
        localVarCredential = this.configuration.lookupCredential('PredictiveMaintenance');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'image/png'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let localVarPath = `/prognosis/api/v1/noiserangegradient`;
        return this.httpClient.request('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Returns the scale values for frequency analysis.
     * @param deviceId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getScale(deviceId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<number>>;
    public getScale(deviceId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<number>>>;
    public getScale(deviceId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<number>>>;
    public getScale(deviceId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (deviceId === null || deviceId === undefined) {
            throw new Error('Required parameter deviceId was null or undefined when calling getScale.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (PredictiveMaintenance) required
        localVarCredential = this.configuration.lookupCredential('PredictiveMaintenance');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/prognosis/api/v1/sensors/${this.configuration.encodeParam({name: "deviceId", value: deviceId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/scale`;
        return this.httpClient.request<Array<number>>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Provides a list of sensors and their properties.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSensors(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<Sensor>>;
    public getSensors(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<Sensor>>>;
    public getSensors(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<Sensor>>>;
    public getSensors(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (PredictiveMaintenance) required
        localVarCredential = this.configuration.lookupCredential('PredictiveMaintenance');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/prognosis/api/v1/sensors`;
        return this.httpClient.request<Array<Sensor>>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Postpone last alarm of cell for sensor. This will postpone the alarm only if it exist and is open.
     * @param deviceid ID of the sensor
     * @param cellid ID of cell
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postponeAlarm(deviceid: string, cellid: number, body?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<any>;
    public postponeAlarm(deviceid: string, cellid: number, body?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<HttpResponse<any>>;
    public postponeAlarm(deviceid: string, cellid: number, body?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<HttpEvent<any>>;
    public postponeAlarm(deviceid: string, cellid: number, body?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<any> {
        if (deviceid === null || deviceid === undefined) {
            throw new Error('Required parameter deviceid was null or undefined when calling postponeAlarm.');
        }
        if (cellid === null || cellid === undefined) {
            throw new Error('Required parameter cellid was null or undefined when calling postponeAlarm.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (PredictiveMaintenance) required
        localVarCredential = this.configuration.lookupCredential('PredictiveMaintenance');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/prognosis/api/v1/sensors/${this.configuration.encodeParam({name: "deviceid", value: deviceid, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/alarm/${this.configuration.encodeParam({name: "cellid", value: cellid, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int32"})}/postpone`;
        return this.httpClient.request<any>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: body,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Set sensor properties. Sensor properties not existing in the request are kept unchanged.
     * @param deviceid ID of the sensor
     * @param sensor 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public setProperties(deviceid: string, sensor?: Sensor, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<any>;
    public setProperties(deviceid: string, sensor?: Sensor, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<HttpResponse<any>>;
    public setProperties(deviceid: string, sensor?: Sensor, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<HttpEvent<any>>;
    public setProperties(deviceid: string, sensor?: Sensor, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<any> {
        if (deviceid === null || deviceid === undefined) {
            throw new Error('Required parameter deviceid was null or undefined when calling setProperties.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (PredictiveMaintenance) required
        localVarCredential = this.configuration.lookupCredential('PredictiveMaintenance');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/prognosis/api/v1/sensors/${this.configuration.encodeParam({name: "deviceid", value: deviceid, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/setproperties`;
        return this.httpClient.request<any>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: sensor,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
