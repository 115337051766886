import { AbstractControl, ValidatorFn } from '@angular/forms';
import { Org, SubOrg } from '@dpdhl-iot/api/backend';
import { ApplicationConfig } from '@dpdhl/iot-shared-ui';
import { CoreConstants } from '../constants/core-constants';

export const getApplicationConfig = () =>
  //eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/dot-notation
  (window as any)['__applicationConfiguration'] as ApplicationConfig;

export const entitiesEqual = <T>(current: T, previous: T): boolean =>
  JSON.stringify(current) === JSON.stringify(previous);

export const accumulateNumbers = (input: number[]): number =>
  input.reduce((prev, current) => prev + current, 0);

export const validateDuplicate =
  (values: string[]): ValidatorFn =>
  (control: AbstractControl) => {
    const value = control.value;
    if (value && values.includes(value.toLowerCase())) {
      return {
        valid: false,
      };
    }
    return null;
  };

export const validateMobileOrEmail = (value: string) => {
  if (CoreConstants.Email_Regex.test(value)) {
    return null;
  } else if (CoreConstants.Mobile_Regex.test(value)) {
    return null;
  } else {
    return {
      valid: false,
    };
  }
};

export const validateTimeRange = (
  fromTime: { hours: number; minutes: number },
  toTime: { hours: number; minutes: number },
) => {
  if ([fromTime.hours, fromTime.minutes, toTime.hours, toTime.minutes].every((x) => x === null)) {
    return null;
  }
  if ([fromTime.hours, fromTime.minutes, toTime.hours, toTime.minutes].some((x) => x === null)) {
    return {
      valid: false,
    };
  }
  const date = new Date();
  const fromDate = date.setHours(fromTime.hours, fromTime.minutes);
  const toDate = date.setHours(toTime.hours, toTime.minutes);
  if (fromDate === toDate) {
    return {
      valid: false,
    };
  }
  return null;
};

export const isOvernightTimeRange = (
  fromTime: { hours: number; minutes: number },
  toTime: { hours: number; minutes: number },
): boolean => {
  if ([fromTime.hours, fromTime.minutes, toTime.hours, toTime.minutes].every((x) => x === null)) {
    return false;
  }
  if ([fromTime.hours, fromTime.minutes, toTime.hours, toTime.minutes].some((x) => x === null)) {
    return false;
  }
  const date = new Date();
  const fromDate = date.setHours(fromTime.hours, fromTime.minutes);
  const toDate = date.setHours(toTime.hours, toTime.minutes);
  return fromDate > toDate;
};

export const getFacilityBreadcrumbs = (facility: {
  id: string;
  name: string;
}): { routePath: string; displayValue: string } => ({
  routePath: `../${facility.id}`,
  displayValue: facility.name ?? '',
});

export const getAreaBreadcrumbs = (
  facility: Org,
  area: SubOrg,
): { routePath: string; displayValue: string }[] => [
  {
    routePath: `../../${facility.id}`,
    displayValue: facility.name ?? '',
  },
  {
    routePath: `../${area.id}`,
    displayValue: area.name ?? '',
  },
];

export const findNearestNumber = (range: number[], input: number): number =>
  range.length
    ? range.reduce(
        (prev, curr) => (Math.abs(curr - input) < Math.abs(prev - input) ? curr : prev),
        range[0],
      )
    : input;

export const timeStampComparer = (a?: number, b?: number) => (a ?? Infinity) - (b ?? Infinity);

export const numericComparer = (a?: number, b?: number) => (a ?? Infinity) - (b ?? Infinity);

export const textComparer = (a?: string, b?: string) => (a ?? '').localeCompare(b ?? '');

export const setHoursMinutes = (hour: number, minute: number, toUtc: boolean) => {
  const date = new Date();
  if (toUtc) {
    date.setUTCHours(hour, minute);
  } else {
    date.setHours(hour, minute);
  }
  return date;
};

export const celsiusToFahrenheit = (value: number | string): number =>
  parseFloat((Number(value) * 1.8 + 32).toFixed(2));

export const fahrenheitToCelsius = (value: number | string): number =>
  parseFloat(((Number(value) - 32) / 1.8).toFixed(2));
