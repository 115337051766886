import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';
import { Injectable } from '@angular/core';
import { ApplicationSettingValuesService } from '@dpdhl-iot/api/settings';
import { AlertViewModel, CoreConstants } from '@dpdhl-iot/shared';
import { ApplicationDataService } from '@dpdhl/iot-shared-ui';
import { IotApplicationModel } from '@dpdhl/iot-shared-ui/lib/iot-application-shell/api/management-api';
import { TranslocoService } from '@jsverse/transloco';
import { TranslateService } from '@ngx-translate/core';
import { DateTime } from 'luxon';
import { distinctUntilChanged, filter, map, Observable, ReplaySubject, switchMap } from 'rxjs';
import { SensorByPrioritySorter } from '../../global-functions/graph-function';
import { SensorType } from '../graph-management/enums/sensor-type.enum';
import {
  AppType,
  AppViewConfigurationModel,
  ViewConfigurationModel,
} from './view-configuration.model';

@Injectable({
  providedIn: 'root',
})
export class ViewConfigurationService {
  private readonly viewConfigurationSubject = new ReplaySubject<ViewConfigurationModel>(1);

  constructor(
    private readonly applicationDataService: ApplicationDataService,
    private readonly applicationSettingValuesService: ApplicationSettingValuesService,
    private readonly translateService: TranslateService,
    private readonly translocoService: TranslocoService,
  ) {
    registerLocaleData(localeDe, 'de-DE', localeDeExtra);
    this.translateService.setDefaultLang('en');

    this.applicationDataService.application$
      .pipe(
        filter((app) => !!app?.application?.id),
        distinctUntilChanged((prev, curr) => {
          return prev?.application?.id === curr?.application?.id;
        }),
        switchMap((app) =>
          this.applicationSettingValuesService
            .getAllApplicationSettingValues(app.application!.uuid, CoreConstants.API_VERSION)
            .pipe(map((settings) => ({ app, settings }))),
        ),
        switchMap((response) => {
          const language = response.settings[0]?.value?.language === 'DE' ? 'de' : 'en';
          this.translocoService.setActiveLang(language);
          return this.translateService.use(language).pipe(map(() => response));
        }),
      )
      .subscribe((response) => {
        this.updateViewConfiguration(
          response.app.application!,
          response.settings[0]?.value ?? new AppViewConfigurationModel(),
        );
      });
  }

  updateViewConfiguration(
    app: IotApplicationModel,
    settings: AppViewConfigurationModel,
  ): ViewConfigurationModel {
    const viewConfigurationModel: ViewConfigurationModel = new ViewConfigurationModel(app);

    viewConfigurationModel.language = settings.language === 'DE' ? 'de' : 'en';

    viewConfigurationModel.facilityView.show_dscWrappingRobots = settings.showDscWrappingRobots;
    viewConfigurationModel.graph.show_dscWrappingRobots = settings.showDscWrappingRobots;
    viewConfigurationModel.notification.showDeviceNotification = settings.showDeviceNotification;
    viewConfigurationModel.notification.showSensorNotificationHint =
      settings.showSensorNotificationHint;
    viewConfigurationModel.deviceList.includeDeviceLink = settings.includeDeviceLink;

    viewConfigurationModel.navigationMenu = this.createMenuItems();

    if (settings.sortSensorsByPriority) {
      viewConfigurationModel.graph.sensorSorter = SensorByPrioritySorter([
        SensorType.Temperature,
        SensorType.Humidity,
        SensorType.CO2,
      ]);
    }

    if (settings.appType === AppType.InventoryTracking) {
      viewConfigurationModel.alert.showColumn_Device = false;
      viewConfigurationModel.inventoryTracking = true;
    }

    if (settings.appType === AppType.PredictiveMaintenance) {
      viewConfigurationModel.alert.removeValuePrefixes = true;
      viewConfigurationModel.alert.showColumn_Country = false;
      viewConfigurationModel.alert.showColumn_Location = true;
      viewConfigurationModel.alert.showColumn_Threshold = false;
      viewConfigurationModel.alert.showColumn_Value = true;
      viewConfigurationModel.alert.showColumn_FacilityName = false;
      viewConfigurationModel.alert.showColumn_LastComment = true;
      viewConfigurationModel.alert.showExport = true;
      viewConfigurationModel.alert.showNoFindings = true;
      viewConfigurationModel.alert.toRowEntry = this.predMainToRowEntry;
      viewConfigurationModel.areaView.show_predictiveMaintenance = true;
      viewConfigurationModel.facilityView.show_predictiveMaintenance = true;
      viewConfigurationModel.areaView.localizations.area = 'predictiveMaintenance.common.warehouse';
      viewConfigurationModel.facilityView.localizations.facility =
        'predictiveMaintenance.common.facility';
      viewConfigurationModel.areaView.localizations.selectArea =
        'predictiveMaintenance.common.selectArea';
      viewConfigurationModel.facilityView.localizations.selectFacility =
        'predictiveMaintenance.common.selectFacility';
      viewConfigurationModel.navigationMenu = viewConfigurationModel.navigationMenu.filter(
        (menu) =>
          !['facilities', 'alert-rule-groups', 'device-management'].includes(menu.path as string),
      );
      viewConfigurationModel.notification.showSensorNotificationHint = true;
      viewConfigurationModel.predictiveMaintenance = true;
    }

    this.viewConfigurationSubject.next(viewConfigurationModel);
    return viewConfigurationModel;
  }

  predMainToRowEntry(data: AlertViewModel): { [key: string]: string | number | Date } {
    return {
      'Geräte-ID': data.deviceId ?? '',
      Sorter: data.location ?? '',
      Zeitpunkt: DateTime.fromMillis(data.deviceTimestamp).toFormat('dd.MM.yyyy HH:mm'),
      Alarmtyp: data.alertType,
      Schwere: data.severity ?? '',
      Wert: data.threshold,
      Status: data.statusId,
      'Letzer Kommentar': data.alertRemarks ? data.alertRemarks[0]?.remarks : '',
    };
  }

  private createMenuItems() {
    return [
      {
        label: this.translateService.instant('menu.dashboard'),
        helpSection: 'dashboard',
        path: '',
      },
      {
        label: this.translateService.instant('menu.facility'),
        path: 'facilities',
        helpSection: 'facility',
      },
      {
        label: this.translateService.instant('menu.alert'),
        helpSection: 'alerts',
        path: 'alerts',
      },
      {
        label: this.translateService.instant('menu.alertTemplates'),
        path: 'alert-rule-groups',
        helpSection: 'alert-templates',
      },
      {
        label: this.translateService.instant('menu.notifications'),
        helpSection: 'notifications',
        path: 'notifications',
      },
      {
        label: this.translateService.instant('menu.deviceManagement'),
        path: 'device-management',
        helpSection: 'device-management',
      },
    ];
  }

  get currentViewConfiguration$(): Observable<ViewConfigurationModel> {
    return this.viewConfigurationSubject.asObservable();
  }
}
