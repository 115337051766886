import { AlertViewModel } from '@dpdhl-iot/shared';
import { NavigationMenu } from '@dpdhl/iot-shared-ui';
import { IotApplicationModel } from '@dpdhl/iot-shared-ui/lib/iot-application-shell/api/management-api';
import { SensorStandardSorter } from '../../global-functions/graph-function';
import { SensorType } from '../graph-management/enums/sensor-type.enum';

export enum AppType {
  ConditionMonitoring = 'ConditionMonitoring',
  PredictiveMaintenance = 'PredictiveMaintenance',
  InventoryTracking = 'InventoryTracking',
}

export class AppViewConfigurationModel {
  public appType: AppType = AppType.ConditionMonitoring;
  public language: 'EN' | 'DE' = 'EN';
  public showDscWrappingRobots = false;
  public showDeviceNotification = false;
  public showSensorNotificationHint = false;
  public sortSensorsByPriority = false;
  public includeDeviceLink = false;
}

export class ViewConfigurationModel {
  application: IotApplicationModel;

  language: language = 'en';

  navigationMenu: NavigationMenu[] = [];

  inventoryTracking = false;
  predictiveMaintenance = false;

  graph: GraphViewConfiguration = {
    show_dscWrappingRobots: false,
    show_pnpBatteryComo: false,
    sensorSorter: SensorStandardSorter,
  };

  facilityView: FacilityViewConfiguration = {
    show_predictiveMaintenance: false,
    show_dscWrappingRobots: false,
    localizations: {
      facility: 'device.grid.facility',
      selectFacility: 'dashboard.device.selectFacility',
    },
  };

  areaView: AreaViewConfiguration = {
    show_predictiveMaintenance: false,
    localizations: {
      area: 'device.grid.area',
      selectArea: 'dashboard.device.selectArea',
    },
  };

  alert: AlertConfiguration = {
    removeValuePrefixes: false,
    showColumn_Country: true,
    showColumn_Location: true,
    showColumn_Threshold: true,
    showColumn_Value: false,
    showColumn_FacilityName: true,
    showColumn_LastComment: false,
    showColumn_Device: true,
    showExport: false,
    showNoFindings: false,
    toRowEntry: undefined,
  };

  deviceList: DeviceViewConfiguration = {
    includeDeviceLink: false,
    showEditActions: true,
  };

  notification: NotificationViewConfiguration = {
    showDeviceNotification: false,
    showSensorNotificationHint: false,
  };

  constructor(application: IotApplicationModel) {
    this.application = application;
  }
}

type AlertConfiguration = {
  toRowEntry?: (row: AlertViewModel) => { [p: string]: string | number | Date };
  removeValuePrefixes: boolean;
  showColumn_Country: boolean;
  showColumn_Location: boolean;
  showColumn_FacilityName: boolean;
  showColumn_Threshold: boolean;
  showColumn_Value: boolean;
  showColumn_LastComment: boolean;
  showColumn_Device: boolean;
  showExport: boolean;
  showNoFindings: boolean;
};

type AreaViewConfiguration = {
  show_predictiveMaintenance: boolean;
  localizations: {
    area: string;
    selectArea: string;
  };
};

type FacilityViewConfiguration = {
  show_dscWrappingRobots: boolean;
  show_predictiveMaintenance: boolean;

  localizations: {
    facility: string;
    selectFacility: string;
  };
};

type GraphViewConfiguration = {
  show_dscWrappingRobots: boolean;
  show_pnpBatteryComo: boolean;
  sensorSorter: (a: SensorType, b: SensorType) => number;
};

type DeviceViewConfiguration = {
  includeDeviceLink: boolean;
  showEditActions: boolean;
};

type NotificationViewConfiguration = {
  showDeviceNotification: boolean;
  showSensorNotificationHint: boolean;
};

type language = 'de' | 'en';

export const DefaultViewConfiguration = new ViewConfigurationModel({
  id: '',
  uuid: '',
} as IotApplicationModel);
