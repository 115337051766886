import { Injectable } from '@angular/core';
import { getAlertsRequest } from '@dpdhl-iot/alert';
import { AuthorizationCheckService, HierarchyNodeType } from '@dpdhl-iot/api/authorization';
import { AlertModel, AlertService, StatusSeveritiesDays } from '@dpdhl-iot/api/backend';
import {
  CellAlertDataHeatMap,
  PrognosisControllerService,
  Sensor,
  ValueAtTime,
} from '@dpdhl-iot/api/prognosis';
import {
  AlertManagementService,
  AlertViewModel,
  ApplicationFacilityService,
  CoreConstants,
} from '@dpdhl-iot/shared';
import { DateTime } from 'luxon';
import {
  BehaviorSubject,
  catchError,
  concatMap,
  forkJoin,
  map,
  mergeMap,
  Observable,
  of,
  tap,
} from 'rxjs';
import {
  AlertDetail,
  AreaAlertSensorMetrics,
  CellAlertSeverity,
  DeviceDetailsAlert,
  DeviceDetailsPredictiveMaintenance,
  DeviceSensorData,
  SensorAlertType,
  SensorData,
} from './predictive-maintenance.models';

@Injectable({
  providedIn: 'root',
})
export class PredictiveMaintenanceService {
  static readonly FillerGroupName = '$FILLERGROUP';
  static readonly ExclusionGroupName = '$EXCLUSIONGROUP';

  private readonly predictiveMaintenanceDevicesProvider = new BehaviorSubject<
    DeviceDetailsPredictiveMaintenance[]
  >([]);
  // eslint-disable-next-line @typescript-eslint/member-ordering
  public predictiveMaintenanceDevices = this.predictiveMaintenanceDevicesProvider.asObservable();

  private readonly filteredPredictiveMaintenanceDevicesProvider = new BehaviorSubject<
    DeviceDetailsPredictiveMaintenance[]
  >([]);
  // eslint-disable-next-line @typescript-eslint/member-ordering
  public filteredPredictiveMaintenanceDevices =
    this.filteredPredictiveMaintenanceDevicesProvider.asObservable();

  constructor(
    private readonly prognosisService: PrognosisControllerService,
    private readonly authorizationService: AuthorizationCheckService,
    private readonly alertManagementService: AlertManagementService,
    private readonly applicationFacilityService: ApplicationFacilityService,
    private readonly alertService: AlertService,
  ) {}

  getAreasWithSensors(facilityId: string, statusSeveritiesDays: StatusSeveritiesDays[]) {
    return forkJoin([
      this.applicationFacilityService.getFacilityAlertMetrics(facilityId, statusSeveritiesDays),
      this.prognosisService.getSensors(),
    ]).pipe(
      map(([areas, sensors]) => {
        return areas.map((area) => {
          const areaAlertSensor = area as AreaAlertSensorMetrics;
          const areaSensors = sensors.filter(
            (x) => x.deviceAccessGroup === area.deviceAccessGroupId,
          );
          areaAlertSensor.devices = areaSensors.map((x) => ({
            id: x.deviceId,
            name: x.name,
            deviceAccessGroupId: x.deviceAccessGroup,
          }));
          areaAlertSensor.totalCells = areaSensors[0]?.beltCells ?? 0;
          areaAlertSensor.hasMissingParameters = areaSensors.some(
            (x) => !x.beltLength || !x.beltSpeed || !x.beltCells,
          );
          return areaAlertSensor;
        });
      }),
    );
  }

  updateDevices(deviceAccessGroups: string[], alertModels: AlertViewModel[] = []) {
    forkJoin([
      alertModels.length
        ? of(alertModels)
        : this.alertManagementService.getAlertsRecursive(getAlertsRequest(deviceAccessGroups)),
      this.prognosisService.getSensors(),
    ])
      .pipe(
        // Set DeviceName for alerts
        tap(([alerts, sensors]) => {
          alerts.forEach((alert) => {
            alert.deviceName = sensors.find((a) => a.deviceId === alert.deviceId)?.name;
          });
        }),
        // Map sensors to DeviceDetailsPredictiveMaintenance
        map(([alerts, sensors]) =>
          sensors.map((sensor) => {
            const alertsForDevice = alerts.filter((a) => a.deviceId === sensor.deviceId);
            return this.mapSensorToDevice(sensor, alertsForDevice);
          }),
        ),
        // If no Cell Groups are available, add Exclusion Group and Filler Group
        tap((devices) => {
          devices
            .filter((a) => !a.sensor.cellGroups?.length)
            .forEach((device) => {
              device.sensor.cellGroups = [
                {
                  position: 0,
                  definition: '',
                  circulations: 400,
                  description: PredictiveMaintenanceService.ExclusionGroupName,
                },
                {
                  circulations: 400,
                  position: 1,
                  definition: '*',
                  description: PredictiveMaintenanceService.FillerGroupName,
                },
              ];
            });
        }),
        // Read the number of circulations and add them
        mergeMap((sensors) =>
          forkJoin(
            sensors.map((sensor) =>
              this.getCirculations(sensor.deviceId!).pipe(
                tap((circulations) => {
                  sensor.circulations = circulations;
                }),
              ),
            ),
          ).pipe(map(() => sensors)),
        ),
        tap((sensor) => this.predictiveMaintenanceDevicesProvider.next(sensor)),
      )
      .subscribe();
  }

  getVolumeLevels(
    sensors: Sensor[],
    from: Date,
    to: Date,
  ): Observable<[DeviceSensorData[], DeviceSensorData[]]> {
    const averageCalls = sensors.map((sensor) =>
      this.prognosisService
        .getAverageVolumeLevels(sensor.deviceId, 'RELATIVE', from.getTime(), to.getTime())
        .pipe(map((volume) => this.mapDeviceVolumeLevels(sensor, volume))),
    );
    const maxCalls = sensors.map((sensor) =>
      this.prognosisService
        .getMaximumVolumeLevels(sensor.deviceId, 'RELATIVE', from.getTime(), to.getTime())
        .pipe(map((volume) => this.mapDeviceVolumeLevels(sensor, volume))),
    );

    return forkJoin(averageCalls).pipe(
      concatMap((avg) =>
        forkJoin(maxCalls).pipe(
          map((max) => [avg, max] as [DeviceSensorData[], DeviceSensorData[]]),
        ),
      ),
    );
  }

  getBeltLength(
    sensor: Sensor,
    from: Date,
    until: Date,
  ): Observable<[SensorData[], { day: Date; avg: number }[]]> {
    return this.prognosisService
      .getBeltLengthDeviations(sensor.deviceId, from.getTime(), until.getTime())
      .pipe(
        map((volumeLevel) => this.mapVolumeLevels(sensor, volumeLevel)),
        map((res) => {
          const averages = this.getVolumeLevelAverages(
            res.map((v) => ({ date: v.date.getTime(), value: v.value! })),
          );
          return [res, averages];
        }),
      );
  }

  getCurrentVolumeLevel(deviceId: string): Observable<SensorData> {
    return this.prognosisService
      .getCurrentVolumeLevel(deviceId, 'RELATIVE')
      .pipe(map((volumeLevel) => this.mapVolumeLevel(volumeLevel)));
  }

  getNextBeltStart(deviceId: string, from: Date, to: Date): Observable<Array<number>> {
    return this.prognosisService
      .getCirculationStarts(deviceId, from.getTime(), to.getTime())
      .pipe(map((res) => [...res].sort((a, b) => (a > b ? -1 : 1))));
  }

  getFrequencyData(
    deviceId: string,
    from: Date,
    numberCirculations: number,
    colorContrast: number,
  ): Observable<ArrayBuffer> {
    return this.prognosisService
      .getFreqData(
        deviceId,
        from.getTime(),
        1380,
        500,
        numberCirculations,
        undefined,
        colorContrast,
      )
      .pipe(mergeMap((res) => res.arrayBuffer()));
  }

  getNoiseGradient(colorContrast: number): Observable<ArrayBuffer> {
    return this.prognosisService
      .getNoiseRangeGradient(20, 500, colorContrast)
      .pipe(mergeMap((res) => res.arrayBuffer()));
  }

  getScale(deviceId: string): Observable<number[]> {
    return this.prognosisService.getScale(deviceId);
  }

  hasSensorWritePermission(deviceAccessGroupId: string): Observable<boolean> {
    return this.authorizationService.hasPermissionOnNode(
      CoreConstants.API_VERSION,
      CoreConstants.PREDMAIN_SENSOR_WRITE_PERMISSION,
      HierarchyNodeType.DEVICE_ACCESS_GROUP,
      deviceAccessGroupId,
    );
  }

  getCellFrequencyData(
    deviceId: string,
    cell: number,
    from: Date,
    to: Date,
    colorControl: number,
  ): Observable<[ArrayBuffer, number[]]> {
    return this.prognosisService
      .getFreqDataOfCellWithTime(
        deviceId,
        cell,
        from.getTime(),
        to.getTime(),
        1380,
        500,
        colorControl,
      )
      .pipe(
        map((res) => {
          const arrayBuffer = Uint8Array.from(atob(res.imageData!), (c) => c.charCodeAt(0)).buffer;
          return [arrayBuffer, res.timestamps!];
        }),
      );
  }

  getAlertTriggeringFactors(
    deviceId: string,
    cell: number,
    from: Date,
    to: Date,
  ): Observable<[ArrayBuffer, ArrayBuffer, CellAlertDataHeatMap]> {
    return this.prognosisService
      .getAlertTriggeringFactorsOfCell(deviceId, cell, from.getTime(), to.getTime(), 1380, 500)
      .pipe(
        map((res) => {
          const arrayBufferForAlerts = Uint8Array.from(atob(res.heatMapForAlerts!), (c) =>
            c.charCodeAt(0),
          ).buffer;
          const arrayBufferForWarnings = Uint8Array.from(atob(res.heatMapForWarnings!), (c) =>
            c.charCodeAt(0),
          ).buffer;

          return [arrayBufferForAlerts, arrayBufferForWarnings, res];
        }),
      );
  }

  getPresentFrequencyDataAvailability(
    deviceId: string,
    from: Date,
    to: Date,
  ): Observable<ArrayBuffer> {
    return this.prognosisService
      .getFreqDataAvailability(deviceId, from.getTime(), to.getTime(), 1350, 50)
      .pipe(mergeMap((res) => res.arrayBuffer()));
  }

  setSensorProperties(sensor: Sensor): Observable<Sensor> {
    return this.prognosisService.setProperties(sensor.deviceId, sensor);
  }

  setFilteredDevices(predictiveDevices: DeviceDetailsPredictiveMaintenance[]) {
    this.filteredPredictiveMaintenanceDevicesProvider.next(predictiveDevices);
  }

  postponeAlert(deviceId: string, cellId: number) {
    return this.prognosisService.postponeAlarm(deviceId, cellId);
  }

  getAlertForCell(cell: number, alertCells: DeviceDetailsAlert[] | undefined) {
    return !alertCells
      ? undefined
      : [...alertCells].sort((a, b) => a.alertState - b.alertState).find((a) => a.cell === cell);
  }

  migrateCellConditions(
    deviceId: string,
    newStartingOffset: number,
    totalCarriers: number,
  ): Observable<boolean> {
    return this.alertService.updatePredictiveMaintenanceAlertsCarrier(
      deviceId,
      CoreConstants.API_VERSION,
      newStartingOffset,
      totalCarriers,
    );
  }

  // Get the total number of circulations of a sensor of the last 7 days
  private getCirculations(deviceId: string): Observable<number> {
    return this.prognosisService
      .getCirculationsPerDay(
        deviceId,
        DateTime.now().minus({ days: 7 }).toMillis(),
        DateTime.now().toMillis(),
      )
      .pipe(
        map((a) => a.filter((b) => b.value).map((b) => b.value!)),
        map((a) => a.reduce((p, c) => p + c, 0)),
        catchError(() => of(0)),
      );
  }

  private mapSensorToDevice(
    sensor: Sensor,
    alertsForDevice: (AlertViewModel | AlertModel)[],
  ): DeviceDetailsPredictiveMaintenance {
    const cellAlerts = alertsForDevice
      .filter(
        (a) => a.alertType === 'CellAlert' && (a.severity === 'Alert' || a.severity === 'Warning'),
      )
      .map((a) => {
        return {
          cell: +a.threshold!,
          createDate: new Date(a.deviceTimestamp!),
          alertState: a.severity === 'Alert' ? CellAlertSeverity.ALERT : CellAlertSeverity.WARNING,
          alertId: a.id!,
        };
      });

    const inactiveAlerts = alertsForDevice.filter((a) => (a.alertType ?? '') in SensorAlertType);

    return {
      deviceId: sensor.deviceId,
      deviceName: sensor.name,
      deviceSensorTypes: [],
      isActive: !!sensor.recordingEnabled && inactiveAlerts.length === 0,
      lastTransmission:
        inactiveAlerts.find((a) => a.alertType === 'SensorActiveAlert')?.deviceTimestamp ?? -1,
      alertCells: cellAlerts,
      normalCells: sensor.beltCells ? sensor.beltCells - cellAlerts.length : 0,
      totalOpenAlertCount: cellAlerts.length,
      sensor,
      areaName: '',
      alerts: inactiveAlerts.map((a) => {
        return { alertType: a.alertType, alertDate: a.deviceTimestamp } as AlertDetail;
      }),
    };
  }

  private mapDeviceVolumeLevels(sensor: Sensor, volumeLevels: ValueAtTime[]): DeviceSensorData {
    return {
      levelName: sensor.deviceId,
      volumes: this.mapVolumeLevels(sensor, volumeLevels),
      averages: this.getVolumeLevelAverages(
        volumeLevels
          .filter((a) => a.time && a.value)
          .map((v) => ({ date: v.time!, value: v.value! })),
      ),
    } as DeviceSensorData;
  }

  private mapVolumeLevels(sensor: Sensor, volumeLevels: ValueAtTime[]): SensorData[] {
    const maxDiff = (10 * 1000 * sensor.beltLength!) / sensor.beltSpeed!; // 10 Circulations
    let previousDate = volumeLevels[0]?.time ?? 0;
    const result: SensorData[] = [];
    volumeLevels.forEach((volLevel) => {
      const currentDate = volLevel.time!;
      if (currentDate - previousDate > maxDiff) {
        result.push({ date: new Date(currentDate + maxDiff), value: null });
      }
      result.push(this.mapVolumeLevel(volLevel));
      previousDate = currentDate;
    });
    return result;
  }

  private mapVolumeLevel(volumeLevel: ValueAtTime): SensorData {
    return {
      date: new Date(volumeLevel?.time ?? 0),
      value: volumeLevel?.value ?? -1,
    } as SensorData;
  }

  private getVolumeLevelAverages(
    volumeLevels: { date: number; value: number }[],
  ): { day: Date; avg: number }[] {
    // Calculate on average value for every day, based on the last seven days
    const minDate = DateTime.fromMillis(Math.min(...volumeLevels.map((a) => a.date)))
      .endOf('day')
      .toMillis();
    const maxDate = DateTime.fromMillis(Math.max(...volumeLevels.map((a) => a.date)))
      .endOf('day')
      .toMillis();
    const averages: { day: Date; avg: number }[] = [];
    for (let loopTime = minDate; loopTime <= maxDate; loopTime += 86400000) {
      const lastDate = DateTime.fromMillis(loopTime).minus({ day: 7 }).toMillis();
      const avgValues = volumeLevels
        .filter((re) => re.date > lastDate && re.date <= loopTime)
        .map((a) => a.value);
      const sum = avgValues.reduce((a, b) => a + b, 0);
      const avg = sum / avgValues.length || 0;
      averages.push({ day: new Date(loopTime), avg });
    }
    return averages;
  }
}
